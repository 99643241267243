// @ts-nocheck
import React, {useEffect} from 'react';
import { useFormContext } from 'react-hook-form';

interface FormSelectProps {
    name: string;
    label: string;
    options: { label: string; value: string|number }[];
    value?: string|number;
}

export const FormSelect: React.FC<FormSelectProps> = ({ name, label, options,value }) => {
    const { register, formState: { errors } ,setValue} = useFormContext();


    useEffect(() => {
        setValue(name,value);
    }, []);

    // @ts-ignore
    return (
        <div>
            <label className="block text-gray-700 font-medium mb-1">{label}</label>
            <select {...register(name)} className="w-full border rounded-md py-2 px-3">
                <option value="">Seleccione...</option>
                {options.map((option) => (
                    <option key={option.value} value={option.value}>{option.label}</option>
                ))}
            </select>
            {errors[name] && <p className="text-red-500 text-sm">{errors[name]?.message}</p>}
        </div>
    );
};

