import React, { useState } from 'react';
import { UserMenu } from './UserMenu';

export const Header: React.FC = () => {
    const [userMenuOpen, setUserMenuOpen] = useState(false);

    return (
        <header className="bg-white dark:bg-gray-800 shadow-md px-6 py-4 flex justify-between items-center relative">
            <div className="text-2xl font-bold text-gray-800 dark:text-gray-100">Sistema de Inventario</div>

            <div className="flex items-center space-x-4 relative">
                {/* User Icon and Name */}
                <div
                    className="relative flex items-center space-x-2 cursor-pointer"
                    onClick={() => setUserMenuOpen(!userMenuOpen)}
                >
                    <img
                        src="https://via.placeholder.com/32"
                        alt="User Avatar"
                        className="w-8 h-8 rounded-full"
                    />
                    <span className="text-gray-800 dark:text-gray-100 font-medium">
                        Pedro Araya
                    </span>
                    <svg className="w-4 h-4" fill="currentColor" viewBox="0 0 20 20">
                        <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414L10 13.414l-4.707-4.707a1 1 0 010-1.414z" clipRule="evenodd" />
                    </svg>
                </div>

                {userMenuOpen && <UserMenu onClose={() => setUserMenuOpen(false)} />}
            </div>
        </header>
    );
};




