// useOrderCalculation.js
import { useState, useEffect } from 'react';

const IVA_RATE = 0.19; // 19% de IVA

const useOrderCalculation = (products) => {
    const [totals, setTotals] = useState({
        subtotal: 0,
        iva: 0,
        otherTaxes: 0,
        total: 0,
    });

    useEffect(() => {
        let subtotal = 0;
        let iva = 0;
        let otherTaxes = 0;

        products.forEach((product) => {
            const lineSubtotal = product.quantity * product.unitPrice;
            const discountAmount = lineSubtotal * (product.discount || 0) / 100;
            const lineTotal = lineSubtotal - discountAmount;

            subtotal += lineTotal;
            iva += lineTotal * IVA_RATE;
            otherTaxes += product.otherTaxes || 0;
        });

        const total = subtotal + iva + otherTaxes;

        setTotals({
            subtotal,
            iva,
            otherTaxes,
            total,
        });
    }, [products]);

    return totals;
};

export default useOrderCalculation;



