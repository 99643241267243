// @ts-nocheck
import React, { useState } from 'react';
import { PrivateLayout } from '../../layouts/PrivateLayout';
import { DataTable } from '../../components/ui/DataTable';
import Card from '../../components/ui/Card';
import { ConfirmationModal } from '../../components/ui/ConfirmationModal';
import { FaEdit, FaTrashAlt } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const columns = [
    { id: 1, label: 'Número', key: 'orderNumber', type: 'text', isSortable: true },
    { id: 2, label: 'Canal de venta', key: 'salesChannel', type: 'text', isSortable: true },
    { id: 3, label: 'Estado', key: 'status', type: 'text', isSortable: true },
    { id: 4, label: 'Cliente', key: 'client', type: 'text', isSortable: true },
    { id: 5, label: 'Total', key: 'total', type: 'number', isSortable: true, isCurrency: true },
    { id: 6, label: 'Fecha de pago', key: 'paymentDate', type: 'date', isSortable: true },
    { id: 7, label: 'Deuda', key: 'debt', type: 'number', isSortable: true, isCurrency: true },
    { id: 8, label: 'Fecha de registro', key: 'registrationDate', type: 'date', isSortable: true },
];

const initialOrders = [
    { id: 1, orderNumber: '0000015', salesChannel: 'Canal directo', status: 'Pendiente', client: 'alseco', total: 40000, paymentDate: null, debt: 40000, registrationDate: '2024-10-25 12:10:35' },
    { id: 2, orderNumber: '0000014', salesChannel: 'Canal directo', status: 'Pendiente', client: 'alseco', total: 30000, paymentDate: null, debt: 30000, registrationDate: '2024-10-25 02:48:16' },
    { id: 3, orderNumber: '0000013', salesChannel: 'Canal directo', status: 'Pendiente', client: 'alseco', total: 75800, paymentDate: null, debt: 75800, registrationDate: '2024-10-18 14:48:42' },
    // Agrega más órdenes según sea necesario
];

export const Orders: React.FC = () => {
    const [orders, setOrders] = useState(initialOrders);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const navigate = useNavigate();

    const handleDelete = () => {
        setOrders(orders.filter(order => order.id !== selectedOrder.id));
        setDeleteModalOpen(false);
        setSelectedOrder(null);
    };

    const openDeleteModal = (order) => {
        setSelectedOrder(order);
        setDeleteModalOpen(true);
    };

    return (
        <PrivateLayout>
            <div className="my-auto mx-auto p-6 space-y-6 max-w-[1200px]">
                <h1 className="text-2xl font-semibold text-gray-800">Órdenes</h1>

                <Card
                    title="Órdenes"
                    toolActions={() => (
                        <div className="inline-flex rounded-md shadow-sm" role="group">
                            <button
                                onClick={() => navigate('/orders/create')}
                                className="px-4 py-2 text-sm font-medium text-white bg-purple-600 hover:bg-purple-700 rounded-md focus:ring-2 focus:ring-purple-500 focus:outline-none"
                            >
                                Nueva Orden
                            </button>
                            <button
                                onClick={() => console.log("Exportar Órdenes")}
                                className="px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-md hover:bg-gray-100 focus:z-10 focus:ring-2 focus:ring-blue-500 focus:text-blue-700 ml-2"
                            >
                                Exportar Órdenes
                            </button>
                        </div>
                    )}
                >
                    <DataTable
                        columns={columns}
                        items={orders}
                        isActions={true}
                        renderActions={(order) => (
                            <div className="flex space-x-2">
                                <button
                                    onClick={() => navigate(`/orders/edit/${order.id}`)}
                                    className="text-blue-500 hover:text-blue-700"
                                >
                                    <FaEdit />
                                </button>
                                <button
                                    onClick={() => openDeleteModal(order)}
                                    className="text-red-500 hover:text-red-700"
                                >
                                    <FaTrashAlt />
                                </button>
                            </div>
                        )}
                        isCheck={true}
                        onRowClick={(id) => navigate(`/orders/edit/${id}`)}
                        loading={false}
                    />
                </Card>

                {/* Modal de Confirmación de Eliminación */}
                <ConfirmationModal
                    isOpen={isDeleteModalOpen}
                    onClose={() => setDeleteModalOpen(false)}
                    onConfirm={handleDelete}
                    title="Eliminar Orden"
                    message={`¿Estás seguro de que deseas eliminar la orden "${selectedOrder?.orderNumber}"?`}
                />
            </div>
        </PrivateLayout>
    );
};


