import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

interface FormToggleSwitchProps {
    name: string;
    label: string;
    onToggle?: (value: boolean) => void;
}

export const FormToggleSwitch: React.FC<FormToggleSwitchProps> = ({ name, label, onToggle }) => {
    const { register, setValue } = useFormContext();
    const toggleValue = useWatch({ name });

    const handleToggle = () => {
        const newValue = !toggleValue;
        setValue(name, newValue);
        onToggle && onToggle(newValue);
    };

    return (
        <div className="flex items-center">
            <span className="mr-2">{label}</span>
            <button
                type="button"
                className={`w-12 h-6 rounded-full ${toggleValue ? 'bg-green-500' : 'bg-gray-300'}`}
                onClick={handleToggle}
            >
                <span className={`w-4 h-4 bg-white rounded-full transition-transform ${toggleValue ? 'translate-x-6' : 'translate-x-1'}`}></span>
            </button>
        </div>
    );
};
