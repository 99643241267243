import React, { useState } from 'react';

interface SidebarItemProps {
    icon: string;
    label: string;
    link: string;
    subItems?: { icon: string; label: string; link: string }[];
    isOpen: boolean;
}

const SidebarItem: React.FC<SidebarItemProps> = ({ icon, label, link, subItems = [], isOpen }) => {
    const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

    return (
        <div>
            <a
                href={link}
                onClick={(e) => {
                    if (subItems.length > 0) {
                        e.preventDefault();
                        setIsSubMenuOpen(!isSubMenuOpen);
                    }
                }}
                className="flex items-center px-4 py-2 rounded-md hover:bg-purple-700 transition-colors cursor-pointer"
            >
                <span className="text-lg">{icon}</span>
                <span className={`${isOpen ? 'ml-3' : 'hidden'} text-sm font-medium`}>{label}</span>
                {subItems.length > 0 && (
                    <svg
                        className={`w-4 h-4 ml-auto transform transition-transform ${isSubMenuOpen ? 'rotate-90' : ''}`}
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                    >
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7" />
                    </svg>
                )}
            </a>
            {isSubMenuOpen && isOpen && subItems.length > 0 && (
                <div className="ml-8 space-y-2 mt-2">
                    {subItems.map((subItem, index) => (
                        <a key={index} href={subItem.link} className="flex items-center px-4 py-1 text-sm text-purple-200 hover:bg-purple-700 rounded-md transition-colors">
                            <span className="text-lg">{subItem.icon}</span>
                            <span className="ml-2">{subItem.label}</span>
                        </a>
                    ))}
                </div>
            )}
        </div>
    );
};

export default SidebarItem;

