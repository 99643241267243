import React from 'react';

interface DataTablePaginationProps {
    currentPage: number;
    totalPages: number;
    onPageChange: (newPage: number) => void;
    itemsPerPage: number;
    onItemsPerPageChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
    itemsPerPageOptions: number[];
}

const DataTablePagination: React.FC<DataTablePaginationProps> = ({
                                                                     currentPage, totalPages, onPageChange, itemsPerPage, onItemsPerPageChange, itemsPerPageOptions
                                                                 }) => {
    return (
        <div className="flex items-center justify-between mt-4">
            <div>
                <label className="mr-2 text-gray-600">Por página:</label>
                <select className="border rounded px-2 py-1" value={itemsPerPage} onChange={onItemsPerPageChange}>
                    {itemsPerPageOptions.map((option) => (
                        <option key={option} value={option}>{option}</option>
                    ))}
                </select>
            </div>
            <div className="flex space-x-2">
                <button className="px-4 py-2 bg-purple-500 text-white rounded hover:bg-purple-600 disabled:opacity-50"
                        onClick={() => onPageChange(currentPage - 1)} disabled={currentPage === 1}>
                    Anterior
                </button>
                <span>Página {currentPage} de {totalPages}</span>
                <button className="px-4 py-2 bg-purple-500 text-white rounded hover:bg-purple-600 disabled:opacity-50"
                        onClick={() => onPageChange(currentPage + 1)} disabled={currentPage === totalPages}>
                    Siguiente
                </button>
            </div>
        </div>
    );
};

export default DataTablePagination;
