import React from 'react';
import { FaCopy } from 'react-icons/fa';
import { Column, Item } from './types';

interface DataTableRowProps {
    item: Item;
    columns: Column[];
    isCheck: boolean;
    isActions: boolean;
    renderActions?: (id: string | number) => React.ReactNode;
    idKey: string;
    isSelected: boolean;
    onSelectItem: (e: React.ChangeEvent<HTMLInputElement>, itemId: string | number) => void;
    onRowClick?: (itemId: string | number) => void; // Nueva propiedad opcional
}

const DataTableRow: React.FC<DataTableRowProps> = ({
                                                       item, columns, isCheck, isActions, renderActions, idKey, isSelected, onSelectItem, onRowClick
                                                   }) => {
    const handleRowClick = () => {
        if (onRowClick) {
            onRowClick(item[idKey]); // Llama a la función pasada en onRowClick con el id del item
        }
    };

    return (
        <tr
            className="bg-white hover:bg-gray-50 cursor-pointer"
            onClick={handleRowClick} // Llamada a la función de click
        >
            {isCheck && (
                <td className="px-4 py-3" onClick={(e) => e.stopPropagation()}>
                    <input
                        type="checkbox"
                        checked={isSelected}
                        onChange={(e) => onSelectItem(e, item[idKey])}
                        className={`form-checkbox h-4 w-4 text-blue-600`}
                    />
                </td>
            )}
            {columns.map((column) => column.isVisible !== false && (
                <td key={`${item[idKey]}-${column.id}`} className="px-4 py-3">
                    {column.isCopyable ? (
                        <span className="flex items-center space-x-2">
                            <span>{item[column.key || '']}</span>
                            <button
                                onClick={(e) => {
                                    e.stopPropagation(); // Evitar que el botón también dispare onRowClick
                                    navigator.clipboard.writeText(item[column.key || '']);
                                }}
                                className="text-gray-400 hover:text-gray-600"
                            >
                                <FaCopy />
                            </button>
                        </span>
                    ) : (
                        item[column.key || '']
                    )}
                </td>
            ))}
            {isActions && renderActions && (
                <td className="px-4 py-3 text-center" onClick={(e) => e.stopPropagation()}>
                    {renderActions(item[idKey])}
                </td>
            )}
        </tr>
    );
};

export default DataTableRow;



