// @ts-nocheck
import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { DefaultLayout } from '../layouts/DefaultLayout';
import { useNavigate } from 'react-router-dom';

// Esquema de validación con Yup
const schema = yup.object().shape({
    email: yup.string().email('Ingresa un email válido').required('El email es obligatorio'),
    password: yup.string().min(6, 'La contraseña debe tener al menos 6 caracteres').required('La contraseña es obligatoria'),
});

type LoginFormInputs = {
    email: string;
    password: string;
};

const Login: React.FC = () => {
    const navigate = useNavigate();
    const { register, handleSubmit, formState: { errors } } = useForm<LoginFormInputs>({
        resolver: yupResolver(schema),
    });

    const onSubmit = (data: LoginFormInputs) => {
        console.log("Datos de login:", data);
        // Navegación simulada al dashboard
        navigate('/dashboard');
    };

    return (
        <DefaultLayout>
            <div className="bg-white bg-opacity-20 backdrop-filter backdrop-blur-lg rounded-lg shadow-lg p-8 w-full max-w-md mx-auto">
                <h2 className="text-3xl font-bold text-center text-white mb-6">Tech Drink Logística</h2>
                <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
                    <div>
                        <label htmlFor="email" className="block text-sm font-medium text-white">Correo Electrónico</label>
                        <input
                            type="email"
                            id="email"
                            {...register("email")}
                            className={`mt-1 px-4 py-2 w-full border rounded-md text-gray-900 focus:outline-none focus:ring-2 focus:ring-purple-500 ${
                                errors.email ? 'border-red-500' : 'border-gray-300'
                            }`}
                        />
                        {errors.email && <p className="text-red-500 text-sm mt-1">{errors.email.message}</p>}
                    </div>
                    <div>
                        <label htmlFor="password" className="block text-sm font-medium text-white">Contraseña</label>
                        <input
                            type="password"
                            id="password"
                            {...register("password")}
                            className={`mt-1 px-4 py-2 w-full border rounded-md text-gray-900 focus:outline-none focus:ring-2 focus:ring-purple-500 ${
                                errors.password ? 'border-red-500' : 'border-gray-300'
                            }`}
                        />
                        {errors.password && <p className="text-red-500 text-sm mt-1">{errors.password.message}</p>}
                    </div>
                    <div className="flex items-center justify-between">
                        <div className="flex items-center">
                            <input
                                id="remember-me"
                                name="remember-me"
                                type="checkbox"
                                className="h-4 w-4 text-purple-600 focus:ring-purple-500 border-gray-300 rounded"
                            />
                            <label htmlFor="remember-me" className="ml-2 block text-sm text-white">Recuérdame</label>
                        </div>
                        <a href="#" className="text-sm text-purple-200 hover:underline">¿Olvidaste tu contraseña?</a>
                    </div>
                    <button
                        type="submit"
                        className="w-full bg-purple-600 text-white py-2 px-4 rounded-md font-medium hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                    >
                        Iniciar Sesión
                    </button>
                    <div className="text-center mt-4">
                        <a href="#" className="text-sm text-purple-200 hover:underline">¿No tienes una cuenta? Regístrate</a>
                    </div>
                </form>
            </div>
        </DefaultLayout>
    );
};

export default Login;

