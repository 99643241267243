// @ts-nocheck
import React from 'react';
import { useFormContext } from 'react-hook-form';

interface FormInputProps {
    name: string;
    label: string;
    type?: string;
}

export const FormInput: React.FC<FormInputProps> = ({ name, label, type = 'text' }) => {
    const { register, formState: { errors } } = useFormContext();

    return (
        <div>
            <label className="block text-gray-700 font-medium mb-1">{label}</label>
            <input
                {...register(name)}
                type={type}
                className="w-full border rounded-md py-2 px-3"
            />
            {errors[name] && <p className="text-red-500 text-sm">{errors[name]?.message}</p>}
        </div>
    );
};

