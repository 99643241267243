import React, { useState } from 'react';
import DataTableHeader from './DataTableHeader';
import DataTableRow from './DataTableRow';
import DataTablePagination from './DataTablePagination';
import DataTableFooter from './DataTableFooter';

import { Column, Item, Props } from './types';

export const DataTable: React.FC<Props> = ({
                                               columns,
                                               items = [],
                                               isCheck = false,
                                               idKey = 'id',
                                               isActions = false,
                                               renderActions,
                                               color = {},
                                               loading = false,
                                               onSortChange,
                                               renderBulkActions,
                                               itemsPerPageOptions = [10, 20, 50],
                                               defaultItemsPerPage = 10,
                                               footerSummary = false,
                                               onRowClick, // Nueva propiedad
                                           }) => {
    const [sortAsc, setSortAsc] = useState(true);
    const [sortedColumn, setSortedColumn] = useState<string | undefined>();
    const [selectedItems, setSelectedItems] = useState<(string | number)[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(defaultItemsPerPage);

    const totalPages = Math.ceil(items.length / itemsPerPage);
    const displayedItems = items.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    const handleSort = (columnKey?: string) => {
        if (!columnKey) return;
        const newSortAsc = sortedColumn === columnKey ? !sortAsc : true;
        setSortAsc(newSortAsc);
        setSortedColumn(columnKey);
        onSortChange && onSortChange(columnKey, newSortAsc ? 'asc' : 'desc');
    };

    const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedItems(e.target.checked ? displayedItems.map((item) => item[idKey]) : []);
    };

    const handleSelectItem = (e: React.ChangeEvent<HTMLInputElement>, itemId: string | number) => {
        setSelectedItems((prevSelected) =>
            e.target.checked ? [...prevSelected, itemId] : prevSelected.filter((id) => id !== itemId)
        );
    };

    const handlePageChange = (newPage: number) => {
        setCurrentPage(newPage);
    };

    const handleItemsPerPageChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setItemsPerPage(Number(e.target.value));
        setCurrentPage(1);
    };

    return (
        <div className="p-5">
            {selectedItems.length > 0 && renderBulkActions && (
                <div className="mb-4">{renderBulkActions(selectedItems)}</div>
            )}
            <table className="min-w-full bg-white rounded-lg shadow overflow-hidden ">
                <DataTableHeader
                    columns={columns}
                    isCheck={isCheck}
                    onSortChange={handleSort}
                    sortedColumn={sortedColumn}
                    sortAsc={sortAsc}
                    onSelectAll={handleSelectAll}
                    selectedAll={selectedItems.length === displayedItems.length}
                    isActions={isActions}
                />
                <tbody>
                {loading ? (
                    <tr>
                        <td colSpan={columns.length + (isCheck ? 1 : 0) + (isActions ? 1 : 0)}
                            className="text-center py-4">
                            Cargando...
                        </td>
                    </tr>
                ) : displayedItems.length === 0 ? (
                    <tr>
                        <td colSpan={columns.length + (isCheck ? 1 : 0) + (isActions ? 1 : 0)}
                            className="text-center py-4">
                            No hay registros
                        </td>
                    </tr>
                ) : (
                    displayedItems.map((item) => (
                        <DataTableRow
                            key={item[idKey]}
                            item={item}
                            columns={columns}
                            isCheck={isCheck}
                            isActions={isActions}
                            renderActions={renderActions}
                            idKey={idKey}
                            isSelected={selectedItems.includes(item[idKey])}
                            onSelectItem={handleSelectItem}
                            onRowClick={onRowClick} // Pasar la propiedad
                        />
                    ))
                )}
                </tbody>
                {footerSummary && <DataTableFooter columns={columns} isCheck={isCheck} isActions={isActions} />}
            </table>
            <DataTablePagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
                itemsPerPage={itemsPerPage}
                onItemsPerPageChange={handleItemsPerPageChange}
                itemsPerPageOptions={itemsPerPageOptions}
            />
        </div>
    );
};

export default DataTable;

