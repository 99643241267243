import React from 'react';

interface SidebarTitleProps {
    title: string;
    isOpen: boolean;
}

const SidebarTitle: React.FC<SidebarTitleProps> = ({ title, isOpen }) => {
    return (
        <h3 className={`${isOpen ? 'block' : 'hidden'} text-sm font-semibold text-purple-400 uppercase mt-4 mb-2`}>
            {title}
        </h3>
    );
};

export default SidebarTitle;
