import React, { FunctionComponent, ReactNode, useState } from 'react';

interface CardProps {
    title: string; // El título de la tarjeta
    children: ReactNode; // Los elementos hijos que Card va a envolver
    isNewButton?: boolean; // Opcional: si se muestra un botón de "Nuevo"
    isOrderButton?: boolean; // Opcional: si se muestra un botón de "Ordenar"
    isFilterButton?: boolean; // Opcional: si se muestra un botón de "Filtrar"
    actionNew?: () => void; // Opcional: función a ejecutar cuando se hace clic en Nuevo
    actionOrder?: () => void; // Opcional: función a ejecutar cuando se hace clic en Ordenar
    toolActions?: () => ReactNode; // Opcional: una función que renderiza nodos adicionales de React, como botones o enlaces
    filterContent?: ReactNode; // El contenido del panel de filtro, como <FilterPanel />
}

export const Card: FunctionComponent<CardProps> = ({
                                                       title,
                                                       children,
                                                       isNewButton = false,
                                                       isOrderButton = false,
                                                       isFilterButton = false,
                                                       actionNew,
                                                       actionOrder,
                                                       toolActions,
                                                       filterContent,
                                                   }) => {
    const [isFilterOpen, setFilterOpen] = useState(false); // Controla la visibilidad del panel de filtro

    return (
        <div className="bg-white shadow-lg rounded-lg p-4 border border-gray-200">
            <div className="flex justify-between items-center border-b border-gray-200 pb-4 mb-4">
                <h3 className="text-xl font-semibold text-gray-800">{title}</h3>
                <div className="flex items-center space-x-2">
                    {isNewButton && (
                        <button
                            onClick={actionNew}
                            className="bg-purple-600 text-white px-4 py-2 rounded-md text-sm hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-500"
                        >
                            Nuevo
                        </button>
                    )}
                    {isOrderButton && (
                        <button
                            onClick={actionOrder}
                            className="bg-gray-200 text-gray-700 px-4 py-2 rounded-md text-sm hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-400"
                        >
                            Ordenar
                        </button>
                    )}
                    {isFilterButton && (
                        <button
                            onClick={() => setFilterOpen(!isFilterOpen)}
                            className="bg-blue-500 text-white px-4 py-2 rounded-md text-sm hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        >
                            Filtrar
                        </button>
                    )}
                    {toolActions && <div>{toolActions()}</div>}
                </div>
            </div>

            {/* Panel de Filtros Condicional */}
            {isFilterOpen && filterContent && (
                <div className="mb-4">
                    {filterContent}
                </div>
            )}

            <div className="card-body">{children}</div>
        </div>
    );
};

export default Card;

