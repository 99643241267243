import React, { useState } from 'react';
import SidebarItem from './SidebarItem';
import SidebarTitle from './SidebarTitle';

// Actualizamos el menú para incluir iconos en los submenús
const menuConfig = [
    {
        title: "Dashboard",
        items: [
            { icon: "📊", label: "Visión General", link: "/dashboard", subItems: [] },
        ],
    },
    {
        title: "Gestión de Productos",
        items: [
            { icon: "📦", label: "Producto", link: "/products", subItems: [] },
            { icon: "📦", label: "Categorias", link: "#", subItems: [] },
        ],
    },
    {
        title: "Inventario",
        items: [
            { icon: "📈", label: "Gestión de Inventario", link: "/inventario", subItems: [] },
            { icon: "⚠️", label: "Alertas de Inventario", link: "#", subItems: [] },
        ],
    },
    {
        title: "Compras",
        items: [
            { icon: "🛒", label: "Órdenes de Compra", link: "#", subItems: [] },
            { icon: "📦", label: "Recepción de Mercadería", link: "#", subItems: [] },
        ],
    },
    {
        title: "Ventas",
        items: [
            { icon: "📄", label: "Gestión de Pedidos", link: "orders", subItems: [] },
        ],
    },
    {
        title: "Reportes",
        items: [
            { icon: "📊", label: "Reportes de Inventario", link: "#", subItems: [] },
            { icon: "📈", label: "Reportes de Ventas y Compras", link: "#", subItems: [] },
        ],
    },
    {
        title: "Configuración",
        items: [
            { icon: "🏢", label: "Empresas", link: "#", subItems: [] },
            { icon: "📂", label: "Categorías y Presentaciones", link: "#", subItems: [] },
        ],
    },
];


export const Sidebar: React.FC = () => {
    const [isOpen, setIsOpen] = useState(true);

    return (
        <aside className={`bg-gradient-to-b from-purple-700 to-purple-900 text-white p-4 transition-all duration-300 ${isOpen ? 'w-64' : 'w-20'}`}>
            <button onClick={() => setIsOpen(!isOpen)} className="text-purple-200 focus:outline-none mb-6">
                <svg className={`w-6 h-6 transform transition-transform duration-300 ${isOpen ? 'rotate-180' : ''}`} fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7" />
                </svg>
            </button>
            {menuConfig.map((section, index) => (
                <div key={index} className="space-y-2">
                    <SidebarTitle title={section.title} isOpen={isOpen} />
                    {section.items.map((item, idx) => (
                        <SidebarItem key={idx} icon={item.icon} label={item.label} link={item.link} subItems={item.subItems} isOpen={isOpen} />
                    ))}
                </div>
            ))}
        </aside>
    );
};

