import React, { useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import {FormInput} from './form/FormInput';
import {FormSelect} from './form/FormSelect';
import {FormToggleSwitch} from './form/FormToggleSwitch';

interface FormField {
    label: string;
    key: string;
    type: 'text' | 'number' | 'date' | 'select' | 'toggle';
    options?: { label: string; value: string }[];
    validation?: Yup.StringSchema | Yup.NumberSchema;
    dependentField?: string;
    defaultValue?: any;
}

interface ModalFormProps {
    isOpen: boolean;
    onClose: () => void;
    onSave: (data: { [key: string]: any }) => void;
    item?: { [key: string]: any };
    title: string;
    formFields: FormField[];
    size?: 'sm' | 'md' | 'lg';
}

export const ModalForm: React.FC<ModalFormProps> = ({ isOpen, onClose, onSave, item, title, formFields, size = 'md' }) => {
    const schema = Yup.object().shape(
        formFields.reduce((acc, field) => {
            if (field.validation) {
                acc[field.key] = field.validation;
            }
            return acc;
        }, {} as { [key: string]: any })
    );

    const methods = useForm({
        resolver: yupResolver(schema),
        defaultValues: item || formFields.reduce((acc, field) => ({ ...acc, [field.key]: '' }), {}),
    });

    const { handleSubmit, reset, watch } = methods;

    useEffect(() => {
        reset(item || formFields.reduce((acc, field) => ({ ...acc, [field.key]: '' }), {}));
    }, [item, reset, formFields]);

    const watchFields = watch();

    const handleSave = (data: any) => {
        onSave(data);
    };

    if (!isOpen) return null;

    const modalSizeClass = size === 'sm' ? 'max-w-md' : size === 'lg' ? 'max-w-4xl' : 'max-w-xl';

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className={`bg-white rounded-lg shadow-lg w-full ${modalSizeClass} p-6`}>
                <h2 className="text-2xl font-semibold text-gray-800 mb-4">{title}</h2>
                <FormProvider {...methods}>
                    <form onSubmit={handleSubmit(handleSave)} className="space-y-4">
                        {formFields.map((field) => {
                            if (field.dependentField && !watchFields[field.dependentField]) {
                                return null; // Ocultar si la condición dependiente no se cumple
                            }

                            switch (field.type) {
                                case 'text':
                                case 'number':
                                case 'date':
                                    return <FormInput key={field.key} name={field.key} label={field.label} type={field.type} />;
                                case 'select':
                                    return <FormSelect key={field.key} name={field.key} label={field.label} options={field.options || []} value={field.defaultValue}/>;
                                case 'toggle':
                                    return <FormToggleSwitch key={field.key} name={field.key} label={field.label} />;
                                default:
                                    return null;
                            }
                        })}
                        <div className="flex justify-end space-x-4 mt-6">
                            <button
                                type="button"
                                onClick={onClose}
                                className="bg-gray-500 text-white py-2 px-4 rounded-md hover:bg-gray-600"
                            >
                                Cancelar
                            </button>
                            <button
                                type="submit"
                                className="bg-purple-500 text-white py-2 px-4 rounded-md hover:bg-purple-600"
                            >
                                Guardar
                            </button>
                        </div>
                    </form>
                </FormProvider>
            </div>
        </div>
    );
};

