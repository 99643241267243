// @ts-nocheck
// CreateOrder.js
import React from 'react';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Select from 'react-select';
import { PrivateLayout } from '../../layouts/PrivateLayout';
import { FaPlus, FaTrashAlt } from 'react-icons/fa';
import useOrderCalculation from '../../hooks/useOrderCalculation';

// Validación con Yup
const validationSchema = Yup.object().shape({
  client: Yup.object().required('Cliente es obligatorio'),
  shippingAddress: Yup.object().required('Dirección de envío es obligatoria'),
  billingAddress: Yup.object().required('Dirección de facturación es obligatoria'),
  vendor: Yup.object().required('Vendedor es obligatorio'),
  //paymentType: Yup.string().required('Tipo de pago es obligatorio'),
  products: Yup.array().of(
      Yup.object().shape({
        name: Yup.object().required('Nombre es obligatorio'), // Ajuste aquí para objeto
        presentation: Yup.object().required('Presentación es obligatoria'), // Ajuste aquí para objeto
        quantity: Yup.number().min(1, 'Cantidad mínima es 1').required('Cantidad es obligatoria'),
        unitPrice: Yup.number().required('Precio es obligatorio'),
        discount: Yup.number().min(0).max(100, 'El descuento debe estar entre 0 y 100').optional(),
      })
  ),
});

// Opciones para Select
const clientOptions = [{ value: 'client1', label: 'Cliente 1' }, { value: 'client2', label: 'Cliente 2' }];
const addressOptions = [{ value: 'address1', label: 'Dirección 1' }, { value: 'address2', label: 'Dirección 2' }];
const paymentOptions = [{ value: 'credit', label: 'Crédito' }, { value: 'cash', label: 'Efectivo' }];
const vendorOptions = [{ value: 'vendor1', label: 'Vendedor 1' }, { value: 'vendor2', label: 'Vendedor 2' }];
const productOptions = [
  { value: 'productA', label: 'Producto A', unitPrice: 1000, minSaleQty: 1 },
  { value: 'productB', label: 'Producto B', unitPrice: 2000, minSaleQty: 2 },
    { value: 'productC', label: 'Producto C', unitPrice: 3000, minSaleQty: 3 },
  { value: 'productD', label: 'Producto D', unitPrice: 4000, minSaleQty: 4 },
    { value: 'productE', label: 'Producto E', unitPrice: 5000, minSaleQty: 5 },
    { value: 'productF', label: 'Producto F', unitPrice: 6000, minSaleQty: 6 },
        { value: 'productG', label: 'Producto G', unitPrice: 7000, minSaleQty: 7 },
];

export const CreateOrder = () => {
  const { control, handleSubmit, watch, setValue, formState: { errors } } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      client: null,
      shippingAddress: null,
      billingAddress: null,
      vendor: null,
      paymentType: 'credit',
      products: [{ name: null, presentation: null, quantity: 1, unitPrice: 0, discount: 0 }],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'products',
  });

  const products = watch("products"); // Observa cambios en productos
  const totals = useOrderCalculation(products || []);

  const handleProductChange = (index, field, value) => {
    const updatedProducts = [...products];
    updatedProducts[index] = {
      ...updatedProducts[index],
      [field]: value,
    };

    if (field === 'name') {
      const selectedProduct = productOptions.find(p => p.value === value.value);
      if (selectedProduct) {
        updatedProducts[index].unitPrice = selectedProduct.unitPrice;
        updatedProducts[index].minSaleQty = selectedProduct.minSaleQty;
      }
    }

    if (field === 'quantity' && updatedProducts[index].minSaleQty && value < updatedProducts[index].minSaleQty) {
      updatedProducts[index].quantity = updatedProducts[index].minSaleQty;
    }

    setValue('products', updatedProducts); // Actualiza los productos en el formulario
  };

  const onSubmit = (data) => {
    console.log('Order submitted:', data);
  };

  return (
      <PrivateLayout>
        <div className="max-w-6xl mx-auto p-6">
          <h1 className="text-3xl font-bold mb-6">Crear Orden</h1>
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
            {/* Detalles de la Orden */}
            <div className="p-6 border rounded-lg bg-white space-y-4">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {/* Cliente */}
                <div>
                  <label className="block text-sm font-medium text-gray-700">Cliente</label>
                  <Controller
                      name="client"
                      control={control}
                      render={({field}) => (
                          <Select {...field} options={clientOptions} placeholder="Seleccione cliente"/>
                      )}
                  />
                  {errors.client && <p className="text-red-500 text-sm">{errors.client.message}</p>}
                </div>
                {/* Dirección de envío */}
                <div>
                  <label className="block text-sm font-medium text-gray-700">Dirección de envío</label>
                  <Controller
                      name="shippingAddress"
                      control={control}
                      render={({field}) => (
                          <Select {...field} options={addressOptions} placeholder="Seleccione dirección"/>
                      )}
                  />
                  {errors.shippingAddress && <p className="text-red-500 text-sm">{errors.shippingAddress.message}</p>}
                </div>
                {/* Dirección de facturación */}
                <div>
                  <label className="block text-sm font-medium text-gray-700">Dirección de facturación</label>
                  <Controller
                      name="billingAddress"
                      control={control}
                      render={({field}) => (
                          <Select {...field} options={addressOptions} placeholder="Seleccione dirección"/>
                      )}
                  />
                  {errors.billingAddress && <p className="text-red-500 text-sm">{errors.billingAddress.message}</p>}
                </div>
                {/* Vendedor */}
                <div>
                  <label className="block text-sm font-medium text-gray-700">Vendedor</label>
                  <Controller
                      name="vendor"
                      control={control}
                      render={({field}) => (
                          <Select {...field} options={vendorOptions} placeholder="Seleccione vendedor"/>
                      )}
                  />
                  {errors.vendor && <p className="text-red-500 text-sm">{errors.vendor.message}</p>}
                </div>
                {/* Tipo de pago */}
                <div>
                  <label className="block text-sm font-medium text-gray-700">Tipo de Pago</label>
                  <Controller
                      name="paymentType"
                      control={control}
                      render={({field}) => (
                          <Select {...field} options={paymentOptions} placeholder="Seleccione tipo de pago"/>
                      )}
                  />
                  {errors.paymentType && <p className="text-red-500 text-sm">{errors.paymentType.message}</p>}
                </div>
              </div>
            </div>

            {/* Lista de Productos */}

            {fields.map((item, index) => (
              <div className="p-6 border rounded-lg bg-white space-y-4">
                <div className="flex justify-between items-center">
                  <h2 className="text-xl font-semibold text-gray-800">Producto</h2>
                  <button type="button" onClick={() => remove(index)} className="text-red-500">
                    <FaTrashAlt/>
                  </button>
                </div>
                <div key={item.id} className="grid grid-cols-1 md:grid-cols-8 gap-4 items-center">
                      <div className="col-span-2">
                        <label className="block text-sm font-medium text-gray-700">Nombre</label>
                        <Controller
                            name={`products.${index}.name`}
                            control={control}
                            render={({field}) => (
                                <Select {...field} options={productOptions} placeholder="Seleccione producto"
                                        value={field.value}
                                        onChange={(option) => handleProductChange(index, 'name', option)}/>
                            )}
                        />
                        {errors.products?.[index]?.name &&
                            <p className="text-red-500 text-sm">{errors.products[index].name.message}</p>}
                      </div>
                      <div className="col-span-2">
                        <label className="block text-sm font-medium text-gray-700">Presentación</label>
                        <Controller
                            name={`products.${index}.presentation`}
                            control={control}
                            render={({field}) => (
                                <Select {...field}
                                        options={[{value: 'unit', label: 'Unidad'}, {value: 'box', label: 'Caja'}]}
                                        placeholder="Seleccione presentación"
                                        value={field.value}
                                        onChange={(option) => handleProductChange(index, 'presentation', option)}/>
                            )}
                        />
                        {errors.products?.[index]?.presentation &&
                            <p className="text-red-500 text-sm">{errors.products[index].presentation.message}</p>}
                      </div>
                      <div>
                        <label className="block text-sm font-medium text-gray-700">Cant.</label>
                        <input
                            type="number"
                            {...control.register(`products.${index}.quantity`)}
                            placeholder="Cantidad"
                            className="border rounded px-2 py-1 w-full"
                            onChange={(e) => handleProductChange(index, 'quantity', parseInt(e.target.value))}
                        />
                        {errors.products?.[index]?.quantity &&
                            <p className="text-red-500 text-sm">{errors.products[index].quantity.message}</p>}
                      </div>
                      <div>
                        <label className="block text-sm font-medium text-gray-700">Precio</label>
                        <input
                            type="number"
                            {...control.register(`products.${index}.unitPrice`)}
                            placeholder="Precio"
                            className="border rounded px-2 py-1 w-full"
                            readOnly
                        />
                        {errors.products?.[index]?.unitPrice &&
                            <p className="text-red-500 text-sm">{errors.products[index].unitPrice.message}</p>}
                      </div>
                      <div>
                        <label className="block text-sm font-medium text-gray-700">Dto. %</label>
                        <input
                            type="number"
                            {...control.register(`products.${index}.discount`)}
                            placeholder="Descuento"
                            className="border rounded px-2 py-1 w-full"
                        />
                      </div>
                      <div>
                        <label className="block text-sm font-medium text-gray-700">Total</label>
                        <input
                            type="text"
                            value={(item.unitPrice * item.quantity * (1 - item.discount / 100)).toFixed(0)}
                            readOnly
                            className="border rounded px-2 py-1 w-full"
                        />
                      </div>

                    </div>
              </div>
            ))}

            <div className="flex justify-center mt-4">
              <button type="button"
                      onClick={() => append({name: null, presentation: null, quantity: 1, unitPrice: 0, discount: 0})}
                      className="bg-gray-500 text-white px-4 py-2 rounded flex items-center">
                <FaPlus className="mr-2"/> Agregar Productos
              </button>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-6">
              <div className="bg-white p-4 border rounded-lg">
                <h2 className="text-lg font-semibold mb-2">Resumen</h2>

                <div className="flex items-center mb-2">
                  <label className="text-gray-500 w-1/2">Subtotal</label>
                  <input
                      type="text"
                      value={`$ ${totals.subtotal?.toLocaleString('es-CL') || "0"}`}
                      readOnly
                      className="border rounded px-2 py-1 w-1/2 text-right text-gray-700"
                  />
                </div>

                <div className="flex items-center mb-2">
                  <label className="text-gray-500 w-1/2">Neto</label>
                  <input
                      type="text"
                      value={`$ ${totals.subtotal?.toLocaleString('es-CL') || "0"}`}
                      readOnly
                      className="border rounded px-2 py-1 w-1/2 text-right text-gray-700"
                  />
                </div>

                <div className="flex items-center mb-2">
                  <label className="text-gray-500 w-1/2">Impuesto (19%)</label>
                  <input
                      type="text"
                      value={`$ ${totals.iva?.toLocaleString('es-CL') || "0"}`}
                      readOnly
                      className="border rounded px-2 py-1 w-1/2 text-right text-gray-700"
                  />
                </div>

                <div className="flex items-center mb-2">
                  <label className="text-gray-500 w-1/2">Otros impuestos</label>
                  <input
                      type="text"
                      value={`$ ${totals.otherTaxes?.toLocaleString('es-CL') || "0"}`}
                      readOnly
                      className="border rounded px-2 py-1 w-1/2 text-right text-gray-700"
                  />
                </div>

                <div className="flex items-center">
                  <label className="text-gray-500 w-1/2">Total</label>
                  <input
                      type="text"
                      value={`$ ${totals.total?.toLocaleString('es-CL') || "0"}`}
                      readOnly
                      className="border rounded px-2 py-1 w-1/2 text-right font-semibold text-gray-900"
                  />
                </div>
              </div>
            </div>



            {/* Botones de Acción */}
            <div className="flex space-x-4 mt-6">
              <button type="submit" className="px-4 py-2 bg-purple-600 text-white rounded">Crear</button>
              <button type="button" className="px-4 py-2 bg-white border rounded text-gray-700">Crear y Crear Otro
              </button>
              <button type="button" onClick={() => window.history.back()}
                      className="px-4 py-2 bg-white border rounded text-gray-700">Cancelar
              </button>
            </div>
          </form>
        </div>
      </PrivateLayout>
  );
};























