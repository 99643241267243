import React from 'react';
import { Column } from './types';

interface DataTableFooterProps {
    columns: Column[];
    isCheck: boolean;
    isActions: boolean;
}

const DataTableFooter: React.FC<DataTableFooterProps> = ({ columns, isCheck, isActions }) => (
    <tfoot>
    <tr className="bg-gray-100 text-gray-700 font-semibold">
        <td colSpan={columns.length + (isCheck ? 1 : 0) + (isActions ? 1 : 0)} className="px-4 py-3 text-right">
            Resumen total: CLP 1,234,567
        </td>
    </tr>
    </tfoot>
);

export default DataTableFooter;
