import React, { ReactNode } from 'react';

type DefaultLayoutProps = {
    children: ReactNode;
};

export const DefaultLayout: React.FC<DefaultLayoutProps> = ({ children }) => {
    return (
        <div className="flex items-center justify-center min-h-screen bg-gradient-to-br from-purple-800 to-purple-900">
            <div className="w-full max-w-md p-6">
                {children}
            </div>
        </div>
    );
};

