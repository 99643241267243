import React from 'react';
import { FaUser, FaCog, FaHeart, FaHistory, FaBell, FaGift, FaSignOutAlt } from 'react-icons/fa';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';


interface UserMenuProps {
    onClose: () => void;
}

export const UserMenu: React.FC<UserMenuProps> = ({ onClose }) => {
    const navigate = useNavigate();

    const handleLogout = () => {
        Swal.fire({
            title: '¿Estás seguro?',
            text: '¿Deseas salir de la sesión?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, salir',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.isConfirmed) {
                onClose();
                navigate('/');
            }
        });
    };

    return (
        <div
            className="absolute right-0 top-12 w-64 bg-white dark:bg-gray-800 rounded-lg shadow-lg overflow-hidden z-20"
        >
            <div className="p-4 flex items-center space-x-3 border-b border-gray-300 dark:border-gray-700">
                <img
                    src="https://via.placeholder.com/48"
                    alt="User Avatar"
                    className="w-10 h-10 rounded-full"
                />
                <div>
                    <p className="text-gray-800 dark:text-gray-200 font-semibold">Pedro Araya</p>
                    <p className="text-sm text-yellow-500">Administrador</p>
                </div>
            </div>
            <div className="flex flex-col space-y-1 py-2">
                <button
                    onClick={onClose}
                    className="flex items-center px-4 py-3 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors"
                >
                    <FaUser className="mr-2 text-gray-500 dark:text-gray-400" />
                    Cambiar perfil
                </button>
                <button
                    onClick={onClose}
                    className="flex items-center px-4 py-3 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors"
                >
                    <FaCog className="mr-2 text-gray-500 dark:text-gray-400" />
                    Opciones
                </button>
            </div>
            <div className="border-t border-gray-300 dark:border-gray-700"></div>
            <div className="flex flex-col space-y-1 py-2">
                <button
                    onClick={onClose}
                    className="flex items-center px-4 py-3 text-sm text-orange-500 hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors"
                >
                    <FaHeart className="mr-2" />
                    Favoritos
                </button>
                <button
                    onClick={onClose}
                    className="flex items-center px-4 py-3 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors"
                >
                    <FaHistory className="mr-2 text-gray-500 dark:text-gray-400" />
                    Historial
                </button>
            </div>
            <div className="border-t border-gray-300 dark:border-gray-700"></div>
            <div className="flex flex-col space-y-1 py-2">
                <button
                    onClick={onClose}
                    className="flex items-center px-4 py-3 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors"
                >
                    <FaBell className="mr-2 text-gray-500 dark:text-gray-400" />
                    Notificaciones
                </button>
                <button
                    onClick={onClose}
                    className="flex items-center px-4 py-3 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors"
                >
                    <FaGift className="mr-2 text-gray-500 dark:text-gray-400" />
                    Tarjeta regalo
                </button>
            </div>
            <div className="border-t border-gray-300 dark:border-gray-700"></div>
            <button
                onClick={handleLogout}
                className="flex items-center px-4 py-3 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors"
            >
                <FaSignOutAlt className="mr-2 text-gray-500 dark:text-gray-400" />
                Desconectar
            </button>
        </div>
    );
};




