import React, { useState } from 'react';
import { PrivateLayout } from '../../layouts/PrivateLayout';
import { FilterPanel } from '../../components/ui/FilterPanel';
import { DataTable } from '../../components/ui/DataTable';
import Card from '../../components/ui/Card';
import { ModalForm } from '../../components/ui/ModalForm';
import { ConfirmationModal } from '../../components/ui/ConfirmationModal';
import {FaEdit, FaExchangeAlt, FaTrashAlt} from 'react-icons/fa';
import {useNavigate} from "react-router-dom";

const columns = [
    { id: 1, label: 'ID', key: 'id', type: 'number', isSortable: true },
    { id: 2, label: 'Sku', key: 'sku', type: 'text', isSortable: true, isCopyable: false },
    { id: 3, label: 'Nombre', key: 'name', type: 'text', isSortable: true, isCopyable: true },
    { id: 4, label: 'Categoría', key: 'category', type: 'select', options: [
            { label: 'Whisky', value: 'whisky' },
            { label: 'Vodka', value: 'vodka' },
            { label: 'Vino', value: 'vino' },
        ], isSortable: true },
    { id: 5, label: 'Precio', key: 'price', type: 'number', isSortable: true, isCurrency: true },
    { id: 6, label: 'Stock', key: 'stock', type: 'number', isSortable: true },
    { id: 7, label: 'Última Modificación', key: 'entryDate', type: 'date', isSortable: true },
];

const initialItems = [
    { id: 1, sku:'product-226', name: 'Whisky Escocés', category: 'whisky', price: 80000, stock: 15, entryDate: '2024-01-01' },
    { id: 2, sku:'product-227', name: 'Vodka Premium', category: 'vodka', price: 60000, stock: 20, entryDate: '2024-01-02' },
    { id: 3, sku:'product-228', name: 'Vino Tinto', category: 'vino', price: 15000, stock: 100, entryDate: '2024-01-03' },
    // Agrega más ítems según sea necesario
];

export const Inventory: React.FC = () => {
    const [items, setItems] = useState(initialItems);
    const [filters, setFilters] = useState([]);
    const [isModalOpen, setModalOpen] = useState(false);
    const [isEditModalOpen, setEditModalOpen] = useState(false);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const navigate = useNavigate();

    const handleApplyFilters = (appliedFilters: any[]) => {
        setFilters(appliedFilters);
        let filteredItems = initialItems;

        appliedFilters.forEach(filter => {
            const { columnKey, value } = filter;
            if (value) {
                filteredItems = filteredItems.filter((item) => {
                    if (columns.find(col => col.key === columnKey)?.type === 'text') {
                        return item[columnKey].toLowerCase().includes(value.toLowerCase());
                    }
                    return item[columnKey] === value;
                });
            }
        });

        setItems(filteredItems);
    };

    const handleClearFilters = () => {
        setFilters([]);
        setItems(initialItems);
    };

    const handleSave = (newItem) => {
        setItems([...items, { ...newItem, id: items.length + 1 }]);
        setModalOpen(false);
    };

    const handleEditSave = (editedItem) => {
        setItems(items.map(item => item.id === editedItem.id ? editedItem : item));
        setEditModalOpen(false);
        setSelectedItem(null);
    };

    const handleDelete = () => {
        setItems(items.filter(item => item.id !== selectedItem.id));
        setDeleteModalOpen(false);
        setSelectedItem(null);
    };

    const openEditModal = (item) => {
        setSelectedItem(item);
        setEditModalOpen(true);
    };

    const openDeleteModal = (item) => {
        setSelectedItem(item);
        setDeleteModalOpen(true);
    };


    return (
        <PrivateLayout>
            <div className="my-auto mx-auto p-6 space-y-6 max-w-[1200px]">
                <h1 className="text-2xl font-semibold text-gray-800">Inventario</h1>

                {/* Card con Filtro y Botón Nuevo */}
                <Card
                    title="Stock"
                    toolActions={() => (
                        <div className="inline-flex rounded-md shadow-sm" role="group">
                            <button
                                onClick={() => navigate('/inventario/create')}
                                className="px-4 py-2 text-sm font-medium text-white bg-purple-600 hover:bg-purple-700 rounded-md focus:ring-2 focus:ring-purple-500 focus:outline-none"
                            >
                               Crear Moviemiento
                            </button>
                            <button  className="ml-2 px-4 py-2 text-sm font-medium text-white bg-green-600 hover:bg-green-700 rounded-md focus:ring-2 focus:ring-green-500 focus:outline-none">
                                Exportar
                            </button>
                        </div>
                    )}
                    filterContent={
                        <FilterPanel
                            // @ts-ignore
                            columns={columns}
                            onApplyFilters={handleApplyFilters}
                            onClearFilters={handleClearFilters}
                        />
                    }
                >
                    <DataTable
                        columns={columns}
                        items={items}
                        isActions={true}
                        renderActions={(item) => (
                            <div className="flex space-x-2">
                                <button onClick={() => openEditModal(item)}
                                        className="text-blue-500 hover:text-blue-700">
                                    <FaEdit/>
                                </button>
                                <button onClick={() => openDeleteModal(item)}
                                        className="text-red-500 hover:text-red-700">
                                    <FaTrashAlt/>
                                </button>
                                <button onClick={() => console.log('Movimiento: '+item)}
                                        className="text-green-500 hover:text-red-700">
                                    <FaExchangeAlt/>
                                </button>
                            </div>
                        )}
                        loading={false}
                    />
                </Card>

                {/* Modal para Crear Licor */}
                <ModalForm
                    isOpen={isModalOpen}
                    onClose={() => setModalOpen(false)}
                    onSave={handleSave}
                    title="Nuevo Producto"
                    formFields={[
                        { label: 'Nombre', key: 'name', type: 'text' },
                        { label: 'Categoría', key: 'category', type: 'select', options: [
                                { label: 'Whisky', value: 'whisky' },
                                { label: 'Vodka', value: 'vodka' },
                                { label: 'Vino', value: 'vino' },
                            ]},
                        { label: 'Precio', key: 'price', type: 'number' },
                        { label: 'Stock', key: 'stock', type: 'number' },
                        { label: 'Fecha de Entrada', key: 'entryDate', type: 'date' },
                    ]}
                />

                {/* Modal para Editar Licor */}
                {selectedItem && (
                    <ModalForm
                        isOpen={isEditModalOpen}
                        onClose={() => setEditModalOpen(false)}
                        onSave={(editedItem) => handleEditSave({ ...selectedItem, ...editedItem })}
                        title="Editar Producto"
                        formFields={[
                            { label: 'Nombre', key: 'name', type: 'text', defaultValue: selectedItem.name },
                            { label: 'Categoría', key: 'category', type: 'select', options: [
                                    { label: 'Whisky', value: 'whisky' },
                                    { label: 'Vodka', value: 'vodka' },
                                    { label: 'Vino', value: 'vino' },
                                ], defaultValue: selectedItem.category },
                            { label: 'Precio', key: 'price', type: 'number', defaultValue: selectedItem.price },
                            { label: 'Stock', key: 'stock', type: 'number', defaultValue: selectedItem.stock },
                            { label: 'Fecha de Entrada', key: 'entryDate', type: 'date', defaultValue: selectedItem.entryDate },
                        ]}
                    />
                )}

                {/* Modal de Confirmación de Eliminación */}
                <ConfirmationModal
                    isOpen={isDeleteModalOpen}
                    onClose={() => setDeleteModalOpen(false)}
                    onConfirm={handleDelete}
                    title="Eliminar Producto"
                    message={`¿Estás seguro de que deseas eliminar el producto "${selectedItem?.name}"?`}
                />
            </div>
        </PrivateLayout>
    );
};










