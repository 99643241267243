import React, { useState, FunctionComponent, ReactNode } from 'react';

// Definición de tipo para las columnas disponibles para filtrar
type Column = {
    id: number;
    label: string;
    key: string;
    type: 'text' | 'number' | 'date' | 'select'; // Tipos de datos permitidos
    options?: { label: string; value: any }[]; // Opciones para el tipo 'select'
};

type FilterValue = {
    columnKey: string;
    value: any;
};

interface FilterPanelProps {
    columns: Column[];
    onApplyFilters: (filters: FilterValue[]) => void;
    onClearFilters: () => void;
}

export const FilterPanel: FunctionComponent<FilterPanelProps> = ({ columns, onApplyFilters, onClearFilters }) => {
    const [filters, setFilters] = useState<FilterValue[]>([]);

    const handleAddFilter = () => {
        setFilters([...filters, { columnKey: columns[0].key, value: '' }]);
    };

    const handleRemoveFilter = (index: number) => {
        setFilters(filters.filter((_, i) => i !== index));
    };

    const handleFilterChange = (index: number, columnKey: string, value: any) => {
        const updatedFilters = [...filters];
        updatedFilters[index] = { columnKey, value };
        setFilters(updatedFilters);
    };

    const handleApplyFilters = () => {
        onApplyFilters(filters);
    };

    return (
        <div className="bg-white shadow-md rounded-lg p-6 mb-6">
            <h3 className="text-lg font-semibold mb-4 text-gray-800">Filtros</h3>
            <div className="space-y-4">
                {filters.map((filter, index) => (
                    <FilterField
                        key={index}
                        columns={columns}
                        filter={filter}
                        onChange={(columnKey, value) => handleFilterChange(index, columnKey, value)}
                        onRemove={() => handleRemoveFilter(index)}
                    />
                ))}
                <button
                    type="button"
                    onClick={handleAddFilter}
                    className="bg-purple-500 text-white py-2 px-4 rounded-md hover:bg-purple-600 focus:outline-none focus:ring-2 focus:ring-purple-500"
                >
                    + Agregar Filtro
                </button>
            </div>
            <div className="mt-6 flex justify-end space-x-4">
                <button
                    type="button"
                    onClick={handleApplyFilters}
                    className="bg-green-500 text-white py-2 px-4 rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500"
                >
                    Aplicar Filtros
                </button>
                <button
                    type="button"
                    onClick={() => { setFilters([]); onClearFilters(); }}
                    className="bg-red-500 text-white py-2 px-4 rounded-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500"
                >
                    Limpiar Filtros
                </button>
            </div>
        </div>
    );
};

interface FilterFieldProps {
    columns: Column[];
    filter: FilterValue;
    onChange: (columnKey: string, value: any) => void;
    onRemove: () => void;
}

const FilterField: FunctionComponent<FilterFieldProps> = ({ columns, filter, onChange, onRemove }) => {
    const selectedColumn = columns.find((col) => col.key === filter.columnKey) || columns[0];

    return (
        <div className="flex items-center space-x-4">
            {/* Selector de columna */}
            <select
                value={filter.columnKey}
                onChange={(e) => onChange(e.target.value, '')}
                className="border rounded-md py-2 px-3 bg-gray-100 focus:outline-none focus:ring-2 focus:ring-purple-500"
            >
                {columns.map((column) => (
                    <option key={column.key} value={column.key}>
                        {column.label}
                    </option>
                ))}
            </select>

            {/* Campo de valor según el tipo de dato */}
            {selectedColumn.type === 'text' && (
                <input
                    type="text"
                    value={filter.value}
                    onChange={(e) => onChange(filter.columnKey, e.target.value)}
                    placeholder="Escribe aquí..."
                    className="border rounded-md py-2 px-3 focus:outline-none focus:ring-2 focus:ring-purple-500"
                />
            )}

            {selectedColumn.type === 'number' && (
                <input
                    type="number"
                    value={filter.value}
                    onChange={(e) => onChange(filter.columnKey, e.target.value)}
                    placeholder="Número"
                    className="border rounded-md py-2 px-3 focus:outline-none focus:ring-2 focus:ring-purple-500"
                />
            )}

            {selectedColumn.type === 'date' && (
                <input
                    type="date"
                    value={filter.value}
                    onChange={(e) => onChange(filter.columnKey, e.target.value)}
                    className="border rounded-md py-2 px-3 focus:outline-none focus:ring-2 focus:ring-purple-500"
                />
            )}

            {selectedColumn.type === 'select' && (
                <select
                    value={filter.value}
                    onChange={(e) => onChange(filter.columnKey, e.target.value)}
                    className="border rounded-md py-2 px-3 bg-gray-100 focus:outline-none focus:ring-2 focus:ring-purple-500"
                >
                    <option value="">Seleccione...</option>
                    {selectedColumn.options?.map((option) => (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </select>
            )}

            {/* Botón para eliminar el filtro */}
            <button
                type="button"
                onClick={onRemove}
                className="text-red-500 hover:text-red-700"
            >
                Eliminar
            </button>
        </div>
    );
};

