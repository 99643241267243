import React, { ReactNode } from 'react';
import { Header } from '../components/Header';
import { Sidebar } from '../components/Sidebar';

type PrivateLayoutProps = {
    children: ReactNode;
};

export const PrivateLayout: React.FC<PrivateLayoutProps> = ({ children }) => {
    return (
        <div className="flex min-h-screen bg-gray-100 text-gray-800 transition-colors duration-300">
            <Sidebar />
            <div className="flex-1 flex flex-col">
                <Header />
                <main className="p-6 space-y-6">
                    {children}
                </main>
            </div>
        </div>
    );
};

