import React from 'react';
import { FaSort, FaSortUp, FaSortDown } from 'react-icons/fa';
import { Column } from './types';

interface DataTableHeaderProps {
    columns: Column[];
    isCheck: boolean;
    onSortChange: (columnKey?: string) => void;
    sortedColumn?: string;
    sortAsc: boolean;
    onSelectAll: (e: React.ChangeEvent<HTMLInputElement>) => void;
    selectedAll: boolean;
    isActions: boolean;
}

const DataTableHeader: React.FC<DataTableHeaderProps> = ({
                                                             columns, isCheck, onSortChange, sortedColumn, sortAsc, onSelectAll, selectedAll, isActions
                                                         }) => {
    return (
        <thead>
        <tr className="bg-gray-100 text-gray-600 text-left text-sm uppercase leading-normal">
            {isCheck && (
                <th className="px-4 py-3">
                    <input type="checkbox" onChange={onSelectAll} checked={selectedAll}  className={`form-checkbox h-4 w-4 text-blue-600`} />
                </th>
            )}
            {columns.map((column) => column.isVisible !== false && (
                <th
                    key={column.id}
                    onClick={() => column.isSortable && onSortChange(column.key)}
                    className="px-4 py-3 cursor-pointer hover:text-purple-600"
                >
                    <span>{column.label}</span>
                    {column.isSortable && (
                        sortedColumn === column.key ? (
                            sortAsc ? <FaSortUp className="ml-2 inline-block text-purple-600"/> : <FaSortDown className="ml-2 inline-block text-purple-600"/>
                        ) : (
                            <FaSort className="ml-2 inline-block text-gray-400"/>
                        )
                    )}
                </th>
            ))}
            {isActions && <th className="px-4 py-3 text-center">Acciones</th>}
        </tr>
        </thead>
    );
};

export default DataTableHeader;
