// @ts-nocheck
// @ts-nocheck
import React, { useState } from 'react';
import { PrivateLayout } from '../../layouts/PrivateLayout';
import { DataTable } from '../../components/ui/DataTable';
import Card from '../../components/ui/Card';
import { ConfirmationModal } from '../../components/ui/ConfirmationModal';
import { FaEdit, FaTrashAlt } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const columns = [
    { id: 1, label: 'SKU', key: 'sku', type: 'text', isSortable: true },
    { id: 2, label: 'Nombre', key: 'name', type: 'text', isSortable: true },
    { id: 3, label: 'Existencia', key: 'stock', type: 'number', isSortable: true },
    { id: 4, label: 'Precio', key: 'price', type: 'number', isSortable: true, isCurrency: true },
    { id: 5, label: 'Tipo de Producto', key: 'productType', type: 'text', isSortable: true },
    { id: 6, label: 'Visibilidad', key: 'visibility', type: 'boolean', isSortable: true },
];

const initialItems = [
    { id: 1, sku: '5HLIL10U', name: 'Servicio de prueba', stock: 'Agotado', price: 3000, productType: 'Servicio', visibility: true },
    { id: 2, sku: 'EOMKDUYP', name: 'Transporte', stock: '480 Presentación 2s', price: 3000, productType: 'Servicio', visibility: true },
    { id: 3, sku: 'sku-laton-budweiser-710', name: 'Latón Cerveza Budweiser 710ml', stock: '2980 units', price: 790, productType: 'Producto', visibility: true },
    { id: 4, sku: 'alseco-hl470cc', name: 'Lata Heineken 470cc', stock: '41 Cajas', price: '450 - 3000', productType: 'Producto', visibility: true },
    // Agrega más ítems según sea necesario
];

export const Products: React.FC = () => {
    const [items, setItems] = useState(initialItems);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const navigate = useNavigate();

    const handleDelete = () => {
        setItems(items.filter(item => item.id !== selectedItem.id));
        setDeleteModalOpen(false);
        setSelectedItem(null);
    };

    const openDeleteModal = (item) => {
        setSelectedItem(item);
        setDeleteModalOpen(true);
    };

    return (
        <PrivateLayout>
            <div className="my-auto mx-auto p-6 space-y-6 max-w-[1200px]">
                <h1 className="text-2xl font-semibold text-gray-800">Productos</h1>

                <Card
                    title="Productos"
                    toolActions={() => (
                        <div className="inline-flex rounded-md shadow-sm" role="group">
                            <button
                                onClick={() => navigate('/product/create')}
                                className="px-4 py-2 text-sm font-medium text-white bg-purple-600 hover:bg-purple-700 rounded-md focus:ring-2 focus:ring-purple-500 focus:outline-none"
                            >
                                Nuevo Producto
                            </button>
                            <button
                                onClick={() => console.log("Importar Producto")}
                                className="px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-md hover:bg-gray-100 focus:z-10 focus:ring-2 focus:ring-blue-500 focus:text-blue-700 ml-2"
                            >
                                Importar Producto
                            </button>
                        </div>
                    )}
                >
                    <DataTable
                        columns={columns}
                        items={items}
                        isActions={true}
                        renderActions={(item) => (
                            <div className="flex space-x-2">
                                <button
                                    onClick={() => navigate(`/product/edit/${item.id}`)}
                                    className="text-blue-500 hover:text-blue-700"
                                >
                                    <FaEdit />
                                </button>
                                <button
                                    onClick={() => openDeleteModal(item)}
                                    className="text-red-500 hover:text-red-700"
                                >
                                    <FaTrashAlt />
                                </button>
                            </div>
                        )}
                        isCheck={true}
                        onRowClick={(id) => navigate(`/product/edit/${id}`)}
                        loading={false}
                    />
                </Card>

                {/* Modal de Confirmación de Eliminación */}
                <ConfirmationModal
                    isOpen={isDeleteModalOpen}
                    onClose={() => setDeleteModalOpen(false)}
                    onConfirm={handleDelete}
                    title="Eliminar Producto"
                    message={`¿Estás seguro de que deseas eliminar el producto "${selectedItem?.name}"?`}
                />
            </div>
        </PrivateLayout>
    );
};





