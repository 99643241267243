// @ts-nocheck
import React, { useState } from 'react';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Select from 'react-select';
import Swal from 'sweetalert2';
import { PrivateLayout } from '../../layouts/PrivateLayout';
import '../../assets/css/form.css';
import { FaPlus, FaTrashAlt } from "react-icons/fa";

const documentTypeOptions = [
    { value: 'entrada', label: 'Entrada' },
    { value: 'salida', label: 'Salida' },
];

const reasonOptions = [
    { value: 'acme', label: 'Acme' },
    { value: 'devolucion', label: 'Devolución' },
];

const productOptions = [
    { value: 'productoA', label: 'Producto A' },
    { value: 'productoB', label: 'Producto B' },
];

export const CreateInventary = () => {
    const [isPurchase, setIsPurchase] = useState(false);
    const { control, handleSubmit, formState: { errors }, reset } = useForm({
        defaultValues: {
            documentType: null,
            reason: '',
            products: [{ name: null, quantity: 0, previousStock: 0, newStock: 0 }],
        }
    });

    const { fields, append, remove } = useFieldArray({
        control,
        name: "products",
    });

    const onSubmit = async (data) => {
        try {
            console.log('Movimiento de Stock Enviado:', data);
            Swal.fire({
                title: 'Éxito',
                text: 'Movimiento creado con éxito',
                icon: 'success',
                confirmButtonText: 'OK'
            });
            reset();
        } catch (error) {
            Swal.fire({
                title: 'Error',
                text: 'Hubo un error al crear el movimiento de stock',
                icon: 'error',
                confirmButtonText: 'OK'
            });
        }
    };

    return (
        <PrivateLayout>
            <div className="my-auto mx-auto p-6 space-y-6 max-w-[1200px]">
                <h1 className="text-3xl font-semibold text-gray-800 mb-6">Movimiento Stocks</h1>
                <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
                    {/*  <div className="p-6 border rounded-lg shadow-md bg-white space-y-4">

                       <div className="flex items-center justify-start space-x-4">
                            <label className="text-lg font-medium text-gray-800">Is Purchase?</label>
                            <div className="relative inline-flex items-center">
                                <input
                                    type="checkbox"
                                    checked={isPurchase}
                                    onChange={(e) => setIsPurchase(e.target.checked)}
                                    className="toggle-checkbox hidden"
                                    id="isPurchase"
                                />
                                <label className="toggle-label block w-10 h-6 bg-gray-400 rounded-full cursor-pointer"
                                       htmlFor="isPurchase"></label>
                                <span
                                    className="absolute left-1 top-1 bg-white w-4 h-4 rounded-full transition-transform"
                                    style={{transform: isPurchase ? 'translateX(16px)' : 'translateX(0)'}}></span>
                            </div>
                        </div>
                    </div>*/}

                    {/* Document Type and Reason */}
                    <div className="p-6 border rounded-lg shadow-md bg-white space-y-6">
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Tipo de movimiento</label>
                                <Controller
                                    name="documentType"
                                    control={control}
                                    render={({field}) => (
                                        <Select
                                            {...field}
                                            options={documentTypeOptions}
                                            isClearable
                                            className="mt-1"
                                            placeholder="Cargar documento"
                                        />
                                    )}
                                />
                                {errors.documentType &&
                                    <p className="text-red-500 text-sm">{errors.documentType.message}</p>}
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Motivo</label>
                                <Controller
                                    name="reason"
                                    control={control}
                                    render={({field}) => (
                                        <Select
                                            {...field}
                                            options={reasonOptions}
                                            isClearable
                                            className="mt-1"
                                            placeholder="Motivo"
                                        />
                                    )}
                                />
                                {errors.reason && <p className="text-red-500 text-sm">{errors.reason.message}</p>}
                            </div>
                        </div>
                    </div>

                    {/* Products Section */}
                    {fields.map((item, index) => (
                        <>
                            <div className="p-6 border rounded-lg shadow-md bg-white space-y-6">
                                <div className="flex justify-between items-center">
                                    <h2 className="text-xl font-semibold text-gray-800">Producto</h2>
                                    <button
                                        type="button"
                                        className="text-red-500 text-sm"
                                        onClick={() => remove(index)}
                                    >
                                        <FaTrashAlt/>
                                    </button>
                                </div>

                                <div key={item.id} className="grid grid-cols-1 md:grid-cols-5 gap-4 items-center mb-4">
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700">Nombre</label>
                                        <Controller
                                            name={`products.${index}.name`}
                                            control={control}
                                            render={({field}) => (
                                                <Select
                                                    {...field}
                                                    options={productOptions}
                                                    isClearable
                                                    className="mt-1"
                                                    placeholder="Select an option"
                                                />
                                            )}
                                        />
                                    </div>
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700">Cant.</label>
                                        <Controller
                                            name={`products.${index}.quantity`}
                                            control={control}
                                            render={({field}) => (
                                                <input
                                                    type="number"
                                                    {...field}
                                                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
                                                />
                                            )}
                                        />
                                    </div>
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700">Existencia
                                            antes</label>
                                        <Controller
                                            name={`products.${index}.previousStock`}
                                            control={control}
                                            render={({field}) => (
                                                <input
                                                    type="number"
                                                    {...field}
                                                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
                                                />
                                            )}
                                        />
                                    </div>
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700">Existencia
                                            después</label>
                                        <Controller
                                            name={`products.${index}.newStock`}
                                            control={control}
                                            render={({field}) => (
                                                <input
                                                    type="number"
                                                    {...field}
                                                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
                                                />
                                            )}
                                        />
                                    </div>

                                </div>

                            </div>


                        </>
                    ))}
                    <button
                        type="button"
                        onClick={() => append({name: null, quantity: 0, previousStock: 0, newStock: 0})}
                        className="px-4 py-2 bg-gray-500 text-white rounded-md hover:bg-gray-600 flex items-center justify-center mx-auto"
                    >
                        <FaPlus className="mr-2"/>
                        Agregar producto
                    </button>

                    {/* Submit Buttons */}
                    <div className="flex space-x-4 mt-6">
                        <button type="submit"
                                className="px-4 py-2 bg-purple-600 text-white rounded-md hover:bg-purple-700">
                            Crear
                        </button>
                        <button
                            type="button"
                            onClick={() => handleSubmit(onSubmit)()}
                            className="px-4 py-2 bg-white border border-gray-300 text-gray-700 rounded-md hover:bg-gray-100"
                        >
                            Crear y crear otro
                        </button>
                        <button
                            type="button"
                            className="px-4 py-2 bg-white border border-gray-300 text-gray-700 rounded-md hover:bg-gray-100"
                            onClick={() => window.history.back()}
                        >
                            Cancelar
                        </button>
                    </div>
                </form>
            </div>
        </PrivateLayout>
    );
};


