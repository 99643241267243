// @ts-nocheck
import { Outlet, Routes, Route } from 'react-router-dom';
import Login from '../pages/Login';
import PrivateRoute from './PrivateRoute';
import React from "react"; // Vamos a crear este componente a continuación.
import {Dashboard} from "../pages/Dashboard";
import {Inventory} from "../pages/inventary";
import {Products} from "../pages/products";
import {CreateProduct} from "../pages/products/CreateProduct";
import {CreateInventary} from "../pages/inventary/CreateInventary";
import {Orders} from "../pages/orders";
import {CreateOrder} from "../pages/orders/createOrder";


// Componentes de ejemplo

const NotFound = () => <div>404 Page Not Found</div>;

const AppRoutes: React.FC = () => {
    return (
        <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
            <Route path="/inventario" element={<PrivateRoute><Inventory /></PrivateRoute>} />
            <Route path="/inventario/create" element={<PrivateRoute><CreateInventary /></PrivateRoute>} />
            <Route path="/products" element={<PrivateRoute><Products /></PrivateRoute>} />
            <Route path="/product/create" element={<PrivateRoute><CreateProduct /></PrivateRoute>} />
            <Route path="/orders" element={<PrivateRoute><Orders /></PrivateRoute>} />
            <Route path="/orders/create" element={<PrivateRoute><CreateOrder /></PrivateRoute>} />
            <Route path="*" element={<NotFound />} />
        </Routes>
    );
};

export default AppRoutes;
