// @ts-nocheck
// @ts-nocheck
import React, { useState } from 'react';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Select from 'react-select';
import Swal from 'sweetalert2';
import { PrivateLayout } from '../../layouts/PrivateLayout';
import '../../assets/css/form.css';
import {FaPlus, FaTrashAlt} from "react-icons/fa";

const validationSchema = Yup.object().shape({
    name: Yup.string().required('El nombre es obligatorio'),
    sku: Yup.string().required('El SKU es obligatorio'),
    category: Yup.object().required('La categoría es obligatoria'),
});

const categoryOptions = [
    { value: 'categoria1', label: 'Categoría 1' },
    { value: 'categoria2', label: 'Categoría 2' },
    { value: 'categoria3', label: 'Categoría 3' },
];

const brandOptions = [
    { value: 'marca1', label: 'Marca 1' },
    { value: 'marca2', label: 'Marca 2' },
    { value: 'marca3', label: 'Marca 3' },
];

const tagOptions = [
    { value: 'oferta', label: 'En oferta' },
    { value: 'nuevo', label: 'Nuevo' },
    { value: 'liquidacion', label: 'Liquidación' },
];

const presentationSizeOptions = [
    { value: 'caja', label: 'Caja' },
    { value: 'pack', label: 'Pack' },
    { value: 'pack6', label: 'Pack de 6' },
    { value: 'unidad', label: 'Unidad' },
];

export const CreateProduct: React.FC = () => {
    const [isService, setIsService] = useState(false);
    const { control, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            name: '',
            sku: '',
            barcode: '',
            category: null,
            brand: null,
            tags: null,
            visibility: true,
            presentations: [{ size: null, units: 1, minSaleQty: 1, price: 0 }],
        }
    });

    const { fields, append, remove } = useFieldArray({
        control,
        name: "presentations",
    });

    const onSubmit = async (data) => {
        try {
            console.log('Formulario enviado:', data);
            Swal.fire({
                title: 'Éxito',
                text: 'Producto creado con éxito',
                icon: 'success',
                confirmButtonText: 'OK'
            });
            reset();
        } catch (error) {
            Swal.fire({
                title: 'Error',
                text: 'Hubo un error al crear el producto',
                icon: 'error',
                confirmButtonText: 'OK'
            });
        }
    };

    return (
        <PrivateLayout>
            <div className="my-auto mx-auto p-6 space-y-6 max-w-[1200px]">
                <h1 className="text-3xl font-semibold text-gray-800 mb-6">Crear Producto</h1>
                <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
                    <div className="p-6 border rounded-lg shadow-md bg-white space-y-4">
                        {/* Toggle Fields */}
                        <div className="flex items-center justify-start">

                            <div className="toggle">
                                <input
                                    type="checkbox"
                                    checked={isService}
                                    onChange={(e) => setIsService(e.target.checked)}
                                    className="toggle-checkbox"
                                    id="isService"
                                />
                                <label className="toggle-label" htmlFor="isService"></label>
                            </div>
                            <label className="text-sm font-medium text-gray-700 ml-6">Es Servicio</label>
                        </div>
                        <div>
                            <p className="text-gray-300">Habilita esta opción si el producto es un servicio.</p>
                        </div>
                    </div>


                    {/* Product Fields */}
                    <div className="p-6 border rounded-lg shadow-md bg-white space-y-6">
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Nombre</label>
                                <Controller
                                    name="name"
                                    control={control}
                                    render={({field}) => (
                                        <input
                                            type="text"
                                            {...field}
                                            className={`mt-1 block w-full px-3 py-2 border ${errors.name ? 'border-red-500' : 'border-gray-300'} rounded-md shadow-sm`}
                                        />
                                    )}
                                />
                                {errors.name && <p className="text-red-500 text-sm">{errors.name.message}</p>}
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-700">SKU</label>
                                <Controller
                                    name="sku"
                                    control={control}
                                    render={({field}) => (
                                        <input
                                            type="text"
                                            {...field}
                                            className={`mt-1 block w-full px-3 py-2 border ${errors.sku ? 'border-red-500' : 'border-gray-300'} rounded-md shadow-sm`}
                                        />
                                    )}
                                />
                                {errors.sku && <p className="text-red-500 text-sm">{errors.sku.message}</p>}
                            </div>
                            {!isService && (
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Código de
                                        barras</label>
                                    <Controller
                                        name="barcode"
                                        control={control}
                                        render={({field}) => (
                                            <input
                                                type="text"
                                                {...field}
                                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
                                            />
                                        )}
                                    />
                                </div>
                            )}
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Categoría</label>
                                <Controller
                                    name="category"
                                    control={control}
                                    render={({field}) => (
                                        <Select
                                            {...field}
                                            options={categoryOptions}
                                            isClearable
                                            className="mt-1"
                                            placeholder="Seleccione una opción"
                                        />
                                    )}
                                />
                                {errors.category &&
                                    <p className="text-red-500 text-sm">{errors.category.message}</p>}
                            </div>
                            {!isService && (
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Marca</label>
                                    <Controller
                                        name="brand"
                                        control={control}
                                        render={({field}) => (
                                            <Select
                                                {...field}
                                                options={brandOptions}
                                                isClearable
                                                className="mt-1"
                                                placeholder="Seleccione una opción"
                                            />
                                        )}
                                    />
                                </div>
                            )}
                            {!isService && (
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Etiquetas</label>
                                    <Controller
                                        name="tags"
                                        control={control}
                                        render={({field}) => (
                                            <Select
                                                {...field}
                                                options={tagOptions}
                                                isMulti
                                                isClearable
                                                className="mt-1"
                                                placeholder="Seleccione una opción"
                                            />
                                        )}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                    {/* Card para los switches */}
                    <div className="p-6 border rounded-lg shadow-md bg-white space-y-4">
                        <div className="flex items-center justify-start">

                            <Controller
                                name="visibility"
                                control={control}
                                render={({field}) => (
                                    <div className="toggle">
                                        <input
                                            type="checkbox"
                                            {...field}
                                            className="toggle-checkbox"
                                            id="visibility"
                                        />
                                        <label className="toggle-label" htmlFor="visibility"></label>
                                    </div>
                                )}
                            />
                            <label className="text-sm font-medium text-gray-700 ml-6"> Visibilidad en el
                                sistema</label>

                        </div>
                        <div>
                            <p className="text-gray-300 t">Permite controlar la visibilidad para otros usuarios del
                                sistema. </p>
                        </div>
                    </div>
                    {/* Presentation Section */}
                    {!isService && fields.map((item, index) => (
                        <div key={item.id} className="p-6 border rounded-lg shadow-md bg-white space-y-6">
                            <div className="flex justify-between items-center">
                                <h2 className="text-xl font-semibold text-gray-800">Presentaciones del Producto</h2>
                                <button
                                    type="button"
                                    className="text-red-500 text-sm"
                                    onClick={() => remove(index)}
                                >
                                    <FaTrashAlt />
                                </button>
                            </div>

                            <div className="grid grid-cols-5 gap-4 items-center">
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Tamaño</label>
                                    <Controller
                                        name={`presentations.${index}.size`}
                                        control={control}
                                        render={({field}) => (
                                            <Select
                                                {...field}
                                                options={presentationSizeOptions}
                                                className="mt-1"
                                                placeholder="Seleccione"
                                                isClearable
                                            />
                                        )}
                                    />
                                </div>

                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Unidad</label>
                                    <Controller
                                        name={`presentations.${index}.units`}
                                        control={control}
                                        render={({field}) => (
                                            <input
                                                type="number"
                                                {...field}
                                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
                                            />
                                        )}
                                    />
                                </div>

                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Cantidad mínima de venta</label>
                                    <Controller
                                        name={`presentations.${index}.minSaleQty`}
                                        control={control}
                                        render={({field}) => (
                                            <input
                                                type="number"
                                                {...field}
                                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
                                            />
                                        )}
                                    />
                                </div>

                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Precio</label>
                                    <Controller
                                        name={`presentations.${index}.price`}
                                        control={control}
                                        render={({field}) => (
                                            <input
                                                type="number"
                                                {...field}
                                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
                                            />
                                        )}
                                    />
                                </div>

                                <div className="flex flex-col space-y-2">
                                    <div className="flex items-center">
                                        <Controller
                                            name={`presentations.${index}.isMainUnit`}
                                            control={control}
                                            render={({field}) => (
                                                <input
                                                    type="checkbox"
                                                    {...field}
                                                    className="form-checkbox h-4 w-4 text-blue-600"
                                                />
                                            )}
                                        />
                                        <label className="ml-2 block text-sm font-medium text-gray-700">Unidad
                                            Principal</label>
                                    </div>

                                    <div className="flex items-center">
                                    <Controller
                                            name={`presentations.${index}.canBeSoldAlone`}
                                            control={control}
                                            render={({field}) => (
                                                <input
                                                    type="checkbox"
                                                    {...field}
                                                    className="form-checkbox h-4 w-4 text-blue-600"
                                                />
                                            )}
                                        />
                                        <label className="ml-2 block text-sm font-medium text-gray-700">Puede ser Vendido Solo</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}

                    {!isService && (
                        <button
                            type="button"
                            onClick={() => append({size: null, units: 1, minSaleQty: 1, price: 0 , isMainUnit: false, canBeSoldAlone: false})}
                            className="px-4 py-2 bg-gray-500 text-white rounded-md hover:bg-gray-600 flex items-center justify-center mx-auto mt-6"
                        >
                            <FaPlus className="mr-2"/>
                            Añadir Presentación
                        </button>
                    )}
                    {/* Submit Buttons */}
                    <div className="flex space-x-4 mt-6">
                        <button type="submit"
                                className="px-4 py-2 bg-purple-600 text-white rounded-md hover:bg-purple-700">
                            Crear
                        </button>
                        <button
                            type="button"
                            onClick={() => handleSubmit(onSubmit)()}
                            className="px-4 py-2 bg-white border border-gray-300 text-gray-700 rounded-md hover:bg-gray-100"
                        >
                            Crear y crear otro
                        </button>
                        <button
                            type="button"
                            className="px-4 py-2 bg-white border border-gray-300 text-gray-700 rounded-md hover:bg-gray-100"
                            onClick={() => window.history.back()}
                        >
                            Cancelar
                        </button>
                    </div>
                </form>
            </div>
        </PrivateLayout>
    );
};













