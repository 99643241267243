// @ts-nocheck
import { PrivateLayout } from "../layouts/PrivateLayout";
import { FaBox, FaChartLine, FaShoppingCart, FaUsers, FaTruck } from "react-icons/fa";
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer, PieChart, Pie, Cell, BarChart, Bar, Legend } from "recharts";

const salesData = [
    { name: 'Ene', ventas: 4000 },
    { name: 'Feb', ventas: 3000 },
    { name: 'Mar', ventas: 5000 },
    { name: 'Abr', ventas: 4000 },
    { name: 'May', ventas: 6000 },
    { name: 'Jun', ventas: 7000 },
];

const inventoryData = [
    { name: 'En Stock', cantidad: 950 },
    { name: 'Agotados', cantidad: 250 },
];

const categoryData = [
    { name: 'Electrónica', value: 400 },
    { name: 'Ropa', value: 300 },
    { name: 'Comida', value: 300 },
    { name: 'Muebles', value: 200 },
];

const stockMovements = [
    { type: 'Entrada', product: 'Producto A', quantity: 100, date: '2024-11-01' },
    { type: 'Salida', product: 'Producto B', quantity: 50, date: '2024-11-02' },
    { type: 'Entrada', product: 'Producto C', quantity: 200, date: '2024-11-03' },
    { type: 'Salida', product: 'Producto D', quantity: 75, date: '2024-11-04' },
];

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

export const Dashboard = () => {
    return (
        <PrivateLayout>
            <h1 className="text-3xl font-bold mb-6">Dashboard</h1>
            <p className="mb-8 text-gray-600">Bienvenido al sistema de inventario. Aquí puedes ver un resumen de las operaciones principales.</p>

            {/* Tarjetas en una sola fila */}
            <div className="flex overflow-x-auto space-x-4 mb-10">
                <div className="w-48 p-4 bg-white rounded-lg shadow-md flex items-center space-x-4">
                    <FaBox className="text-blue-600 text-2xl" />
                    <div>
                        <h2 className="text-sm font-semibold text-gray-800">Inventario</h2>
                        <p className="text-xs">Total Productos: <strong>1200</strong></p>
                        <p className="text-xs">Agotados: <strong>250</strong></p>
                    </div>
                </div>

                <div className="w-48 p-4 bg-white rounded-lg shadow-md flex items-center space-x-4">
                    <FaChartLine className="text-green-600 text-2xl" />
                    <div>
                        <h2 className="text-sm font-semibold text-gray-800">Ventas</h2>
                        <p className="text-xs">Hoy: <strong>$4,500</strong></p>
                        <p className="text-xs">Este Mes: <strong>$120,000</strong></p>
                    </div>
                </div>

                <div className="w-48 p-4 bg-white rounded-lg shadow-md flex items-center space-x-4">
                    <FaShoppingCart className="text-red-600 text-2xl" />
                    <div>
                        <h2 className="text-sm font-semibold text-gray-800">Compras</h2>
                        <p className="text-xs">Hoy: <strong>$3,000</strong></p>
                        <p className="text-xs">Este Mes: <strong>$90,000</strong></p>
                    </div>
                </div>

                <div className="w-48 p-4 bg-white rounded-lg shadow-md flex items-center space-x-4">
                    <FaTruck className="text-yellow-600 text-2xl" />
                    <div>
                        <h2 className="text-sm font-semibold text-gray-800">Órdenes</h2>
                        <p className="text-xs">Pendientes: <strong>15</strong></p>
                        <p className="text-xs">Completadas: <strong>180</strong></p>
                    </div>
                </div>

                <div className="w-48 p-4 bg-white rounded-lg shadow-md flex items-center space-x-4">
                    <FaUsers className="text-purple-600 text-2xl" />
                    <div>
                        <h2 className="text-sm font-semibold text-gray-800">Clientes</h2>
                        <p className="text-xs">Nuevos: <strong>20</strong></p>
                        <p className="text-xs">Total: <strong>450</strong></p>
                    </div>
                </div>
            </div>

            {/* Gráficos en dos por fila */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-10">
                {/* Gráfico de Ventas Mensuales */}
                <div className="bg-white p-6 rounded-lg shadow-md">
                    <h2 className="text-xl font-semibold text-gray-800 mb-4">Ventas Mensuales</h2>
                    <ResponsiveContainer width="100%" height={300}>
                        <LineChart data={salesData}>
                            <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip />
                            <Line type="monotone" dataKey="ventas" stroke="#8884d8" strokeWidth={2} />
                        </LineChart>
                    </ResponsiveContainer>
                </div>

                {/* Gráfico de Barras - Inventario */}
                <div className="bg-white p-6 rounded-lg shadow-md">
                    <h2 className="text-xl font-semibold text-gray-800 mb-4">Resumen de Inventario</h2>
                    <ResponsiveContainer width="100%" height={300}>
                        <BarChart data={inventoryData}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="cantidad" fill="#82ca9d" />
                        </BarChart>
                    </ResponsiveContainer>
                </div>

                {/* Gráfico de Dona - Categorías de Productos */}
                <div className="bg-white p-6 rounded-lg shadow-md">
                    <h2 className="text-xl font-semibold text-gray-800 mb-4">Distribución de Categorías de Productos</h2>
                    <ResponsiveContainer width="100%" height={300}>
                        <PieChart>
                            <Pie
                                data={categoryData}
                                dataKey="value"
                                nameKey="name"
                                cx="50%"
                                cy="50%"
                                outerRadius={100}
                                fill="#8884d8"
                                label
                            >
                                {categoryData.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                ))}
                            </Pie>
                            <Tooltip />
                        </PieChart>
                    </ResponsiveContainer>
                </div>
            </div>

            {/* Tabla de Movimientos de Stock */}
            <div className="bg-white p-6 rounded-lg shadow-md">
                <h2 className="text-xl font-semibold text-gray-800 mb-4">Movimientos de Stock</h2>
                <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                    <tr>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Tipo de Movimiento
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Producto
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Cantidad
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Fecha
                        </th>
                    </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                    {stockMovements.map((movement, index) => (
                        <tr key={index}>
                            <td className="px-6 py-4 whitespace-nowrap">{movement.type}</td>
                            <td className="px-6 py-4 whitespace-nowrap">{movement.product}</td>
                            <td className="px-6 py-4 whitespace-nowrap">{movement.quantity}</td>
                            <td className="px-6 py-4 whitespace-nowrap">{movement.date}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </PrivateLayout>
    );
};


